import apiCall, { baseURL } from 'api';
import {
  Attachment,
  ContinueConversationPayload,
  ConversationActionPayload,
  ConversationWithLastMessage,
  ConversationWithToken,
  FetchBotGalleryPayload,
  FetchBotGalleryResponse,
  GetConversationMessagesPayload,
  MessageInConversation,
  PhotoActionPayload,
  PostMessagePayload,
  RequestPhotoPayload,
  RequestPhotoResponse,
  SendTextMessagePayload,
  SurpriseMessagePayload,
  UpdateCustomInstructionPayload,
  PatchConversationPayload,
  ImageAttachment
} from './types';
import { reactQuery } from 'lib/reactQuery';
import { useAuthStore } from 'store/auth';
import { useModalsStore } from 'store/modals';
import { auth } from 'lib/firebase/firebase-config';
import { reportBug } from 'utils';
import { showToast } from 'components/ui/CustomToast';


export const fetchConversations = async (): Promise<ConversationWithLastMessage[]> => {
  try {
    const request = await apiCall();
    const { data } = await request({ url: `conversations`, method: 'GET' });

    return data.data;
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    return Promise.reject(error);
  }
};

export const postConversation = async (account_id?: number): Promise<ConversationWithLastMessage> => {
  try {
    if (!account_id) return Promise.reject();
    const request = await apiCall();
    const { data } = await request({ url: `conversations`, method: 'POST', data: { account_id } });

    reactQuery.refetchQueries(['all-conversations']);
    return data.data;
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    return Promise.reject(error);
  }
};

export const fetchSingleConversation = async (account_id: string): Promise<ConversationWithToken> => {
  try {
    const request = await apiCall();
    const { data } = await request({ url: `conversations/${+account_id}`, method: 'GET' });

    return data.data;
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    return Promise.reject(error);
  }
};

const imageCategoryMap: Record<string, string> = {
  'Casual': 'safe',
  'Flirty': 'tease',
  'Nude': 'nude',
  'XXX': 'hardcore'
};

export const sendTextMessage = async ({ conversationId, content, bot }: SendTextMessagePayload) => {
  try {
    const request = await apiCall();

    // Parse photo requests here with case-insensitive matching
    const photoRequestMatch = content.match(/^Send me a (Casual|Flirty|Nude|XXX) photo(.*)$/i);
    let image_request = undefined;

    if (photoRequestMatch) {
      const [_, category, promptText] = photoRequestMatch;
      image_request = {
        level: imageCategoryMap[category] || 'safe',
        prompt: promptText.trim().replace(/^of\s+you\s+/i, '').trim()
      };
    }

    const { data } = await request({
      url: `conversations/${conversationId}/messages`,
      method: 'POST',
      data: {
        content,
        image_request
      }
    });

    return data.data;
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    return Promise.reject(error);
  }
};

export const fetchConversationMessages = async ({
  conversationId,
  before_id
}: GetConversationMessagesPayload): Promise<MessageInConversation[]> => {
  try {
    if (!before_id) return [];
    const request = await apiCall();
    const { data } = await request({
      url: `conversations/${conversationId}/messages`,
      method: 'GET',
      params: { before_id }
    });

    return data.data;
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    return Promise.reject(error);
  }
};

export const requestPhoto = async ({ conversationId, request, type }: RequestPhotoPayload) => {
  try {
    const _request = await apiCall();
    const data = await _request<RequestPhotoResponse>({
      url: `conversations/${conversationId}/photos`,
      method: 'POST',
      data: { request, type }
    });

    return data;
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error, prodToast: true });
    return Promise.reject(error);
  }
};

export const unlockPhoto = async ({ id, image_url }: { id: number; image_url: string }): Promise<ImageAttachment> => {
  const { user } = useAuthStore.getState();
  const { setUpsellModal, setCreditsUpsellModal } = useModalsStore.getState();

  try {
    const request = await apiCall();
    const { data } = await request({
      url: `conversations/photos/${id}/unlock`,
      method: 'POST'
    });

    // Ensure the response includes favorited_at
    const response = {
      ...data.data,
      favorited_at: data.data.favorited_at || null
    } as ImageAttachment;

    return response;
  } catch (error: any) {
    if (error?.data?.error_code === 'PREMIUM_ONLY') {
      setUpsellModal({ image: image_url, type: 'premium_photo' });
    } else if (error?.data?.error_code === 'NOT_ENOUGH_BALANCE') {
      if (user?.is_premium) {
        setCreditsUpsellModal(image_url || '');
      } else {
        setUpsellModal({ image: image_url, type: 'out_of_credit', showRefreshCountdown: true });
      }
    } else {
      reportBug({ msg: error?.data?.message, error, prodToast: true });
    }
    return Promise.reject(error);
  }
};

export const getVoiceMessage = async (message_id: number): Promise<string> => {
  try {
    const token = await auth.currentUser?.getIdToken();
    const response = await fetch(`${baseURL}conversations/messages/${message_id}/voice`, {
      method: 'POST',
      headers: {
        Authorization: token!
      }
    });

    if (!response.ok) {
      const data = await response.json();
      if (data?.message === 'Voice message already generated') {
        throw new Error(data.message);
      } else if (data?.message.includes('not have enough credits')) {
        throw new Error('Insufficient balance');
      } else if (data?.message.includes('Insufficient balance')) {
        throw new Error('Insufficient balance');
      } else if (data?.message === 'Voice message is being generated') {
        throw new Error('Voice message is being generated');
      } else {
        throw new Error('Failed to generate voice message');
      }
    }

    const { data } = await response.json();
    const { url, new: isNew } = data;

    return isNew ? `${baseURL}${url.replace('/public/v1/', '')}` : url;
  } catch (err: any) {
    if (err.message && err.message !== 'Insufficient balance' && err.message !== 'Voice message is being generated') {
      reportBug({ msg: err?.message, error: err, prodToast: true });
    }
    return Promise.reject(err);
  }
};

export const fetchBotGallery = async ({
  conversationId,
  ...params
}: FetchBotGalleryPayload): Promise<FetchBotGalleryResponse> => {
  try {
    const request = await apiCall();
    const { data } = await request({ url: `conversations/${conversationId}/photos`, params });
    return data.data;
  } catch (error: any) {
    return { count: 0, data: [] };
  }
};

export const postSurpriseMessage = async ({ conversationId, type }: SurpriseMessagePayload) => {
  try {
    const request = await apiCall();
    const { data } = await request({
      url: `conversations/${conversationId}/surprise`,
      method: 'POST',
      data: { type }
    });
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    return Promise.reject(error);
  }
};

export const postContinueConversation = async ({ conversationId }: ContinueConversationPayload) => {
  try {
    const request = await apiCall();
    const { data } = await request({ url: `conversations/${conversationId}/continue`, method: 'POST' });

    return data.data;
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    return Promise.reject(error);
  }
};

export const postStopConversation = async ({ conversationId }: ContinueConversationPayload) => {
  try {
    const request = await apiCall();
    const { data } = await request({ url: `conversations/${conversationId}/stop`, method: 'POST' });

    return data.data;
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    return Promise.reject(error);
  }
};

export const postPhotoAction = async ({
  photo_id,
  action,
  reason,
  delete: deleteFlag
}: PhotoActionPayload) => {
  try {
    const request = await apiCall();
    const { data } = await request({
      url: `conversations/photos/${photo_id}/${action}`,
      method: 'POST',
      params: {
        ...(reason && { reason }),
        ...(deleteFlag !== undefined && { delete: deleteFlag })
      }
    });

    return data.data;
  } catch (err: any) {
    reportBug({ msg: err?.data?.message, error: err });
    return Promise.reject(err);
  }
};

export const postConversationAction = async ({ conversationId, action }: ConversationActionPayload) => {
  try {
    const request = await apiCall();
    const { data } = await request({ url: `conversations/${conversationId}/${action}`, method: 'POST' });

    return data.data;
  } catch (err: any) {
    reportBug({ msg: err?.data?.message, error: err });
    return Promise.reject;
  }
};

export const putCustomInstructions = async ({
  conversationId,
  custom_instructions
}: UpdateCustomInstructionPayload) => {
  try {
    const request = await apiCall();
    await request({
      url: `conversations/${conversationId}/config`,
      method: 'PUT',
      params: { custom_instructions }
    });
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    return Promise.reject(error);
  }
};

export const patchConversationConfig = async ({
  conversationId,
  allow_implicit_images,
  image_backend
}: PatchConversationPayload) => {
  try {
    const request = await apiCall();
    const data: Partial<{ allow_implicit_images: boolean; image_backend: string | null }> = {};

    data.allow_implicit_images = allow_implicit_images;

    if (image_backend !== undefined) {
      data.image_backend = image_backend;
    }

    await request({
      url: `conversations/${conversationId}/config`,
      method: 'PATCH',
      data
    });
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    return Promise.reject(error);
  }
};


export const postMessageAction = async ({ action, message_id, message_content }: PostMessagePayload) => {
  try {
    const request = await apiCall();
    await request({
      url: `conversations/messages/${message_id}/${action}`,
      method: 'POST',
      data: message_content
    });
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    return Promise.reject(error);
  }
};